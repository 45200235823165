.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  max-width: 959px;
  padding: calc(var(--spacing) * 2) var(--gutter);
}
