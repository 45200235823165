.keyboard {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing);
  width: 100%;
  max-width: max-content;
  padding: var(--spacing) 0 0;
  overflow-x: auto;
}

.keyboardColumn {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.keyboardRow {
  display: flex;
  margin: 0 auto;
}

.keyboardKey,
.keyboardKeyPlaceholder {
  padding: 0.25rem;
  min-width: 28px;
  border-radius: 0.25rem;
  line-height: 1;
  text-align: center;
}

.keyboardKey {
  cursor: pointer;
}

.keyboardKey:active {
  color: var(--light);
  background: var(--teal);
}

@media (hover: hover) {
  .keyboardKey:hover {
    color: var(--light);
    background: var(--teal);
  }
}

.keyboardKeyFrom {
  display: block;
}

.keyboardKeyTo {
  display: block;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-family: sans-serif;
}

.keyboardActions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.keyboardActions > * {
  margin: var(--spacing) 0 0;
}

.keyboardActions > *:not(:last-child) {
  margin-right: var(--spacing);
}

@media (min-width: 960px) {
  .keyboardKey,
  .keyboardKeyPlaceholder {
    padding: 0.5rem;
    min-width: 48px;
  }
}
