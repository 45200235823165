.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--teal);
  transition: transform var(--menu-open-duration);
  z-index: 1;
  display: flex;
  flex-direction: column;
  max-width: var(--menu-width);
  transform: translateX(calc(100% + 1px));
}

.menu[data-open='true'] {
  transform: translateX(0);
}

.menu button {
  position: absolute;
  top: var(--spacing);
  right: var(--spacing);
}

.menu ul {
  display: grid;
  align-content: flex-start;
  gap: calc(var(--spacing) * 2);
  margin: 0 auto;
  font-size: 1.25rem;
  overflow-y: auto;
  line-height: 1;
  padding: calc(var(--spacing) * 2);
  padding-right: calc(var(--spacing) * 2 + var(--menu-button-size));
  width: 100%;
  list-style: none;
}

.menu li[data-active='true'] {
  font-weight: bold;
}

.menu a {
  color: var(--light);
}

.menu ul a {
  text-decoration: none;
}

.menu ul a:hover {
  text-decoration: underline;
}

.menuText {
  padding: calc(var(--spacing) * 2);
  margin-top: auto;
}

.menuText a:hover {
  text-decoration: none;
}

.fadeOut {
  background: rgba(0 0 0 / 0.75);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity var(--menu-open-duration);
}

.fadeOut[data-open='false'] {
  opacity: 0;
  pointer-events: none;
}

.scrollbar::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.scrollbar::-webkit-scrollbar-track {
  background: var(--dark-teal);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--light);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--light-gray);
}

@media (max-width: 959px) {
  .menu {
    width: 100vw;
    max-width: none;
  }
}
