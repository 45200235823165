.textArea {
  min-height: 8rem;
  padding: var(--spacing);
  resize: vertical;
  width: 100%;
  color: inherit;
  border: var(--border-width) solid var(--dark);
  font-family: sans-serif;
}

.textArea::placeholder {
  color: var(--dark-gray);
}

@media (max-width: 959px) {
  .textArea {
    position: sticky;
    min-height: 4rem;
    top: var(--spacing);
  }
}
