.button {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: var(--light);
  color: var(--dark);
  border: var(--border-width) solid var(--dark);
  min-width: var(--button-width);
}

.button:active {
  background: var(--teal);
  color: var(--light);
}

@media (hover: hover) {
  .button:hover {
    background: var(--teal);
    color: var(--light);
  }
}

.menuButton {
  width: var(--menu-button-size);
  height: var(--menu-button-size);
  color: var(--light);
  padding: 0;
}

.menuButton:active {
  color: var(--dark);
}

@media (hover: hover) {
  .menuButton:hover {
    color: var(--dark);
  }
}

.iconButton {
  display: grid;
  grid-template-columns: auto auto;
  gap: calc(var(--spacing) / 2);
  justify-content: center;
}

.toggle {
  display: flex;
}

.toggle > button[data-active='true'] {
  font-weight: bold;
  background: var(--teal);
  color: var(--light);
}

.toggle > button:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toggle > button:not(:first-of-type) {
  margin-left: calc(var(--border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.group {
  display: flex;
}

.group > button:not(:last-of-type) {
  margin-right: calc(var(--spacing) / 2);
}

.spacebar {
  line-height: 0.5;
  font-weight: bold;
}

@media (min-width: 960px) {
  .mobileOnly {
    display: none;
  }
}
