.header {
  width: 100%;
  background: var(--teal);
  color: var(--light);
}

.headerWrapper {
  padding: var(--spacing) var(--gutter);
  max-width: 959px;
  margin: 0 auto;
  display: grid;
  align-items: center;
  gap: calc(var(--spacing) * 1);
  grid-template-columns: auto auto 1fr;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1;
}

.headerWrapper > button:last-child {
  justify-self: flex-end;
}

@media (min-width: 960px) {
  .title {
    font-size: 1.75rem;
  }

  .headerWrapper {
    grid-template-columns: 1fr auto 1fr;
  }
}
